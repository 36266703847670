<template>
  <div
    :class="`hover:transform ${ link ? 'hover:scale-105' :'hover:scale-110 w-max'} hover:cursor-pointer transition duration-300 ease-in-out flex px-[15px] sm:px-[20px] py-[20px] justify-center items-center rounded backdrop-blur-xl text-white text-base font-medium capitalize leading-loose ${buttonClasses}`"
    @click="handleClick"
  >
    <template v-if="link">
      <NuxtLink class="pr-2" :to="newTab ? '' : link" :target="newTab ? '_blank' : ''">
        {{ text }}
      </NuxtLink>
    </template>
    <template v-else>
      <div class="pr-2 cursor-pointer">
        {{ text }}
      </div>
    </template>
    <ArrowRightIcon v-if="icon" class="h-6 w-6 text-white" />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useTimeoutFn } from '@vueuse/core'
import { ArrowRightIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  icon: {
    type: Boolean,
    default: true,
    required: false
  },
  buttonClasses: {
    type: String,
    default: 'bg-[#6F8E91]',
    required: false
  },
  link: {
    type: String,
    default: '',
    required: false
  },
  newTab: {
    type: Boolean,
    default: false,
    required: false
  }
})

const router = useRouter()

const handleClick = () => {
  if (props.link) {
    if (props.newTab) {
      window.open(props.link, '_blank')
    }
    return
  }
  const estimateForm = document.querySelector('#estimate-form')
  if (estimateForm) {
    scrollToElement(estimateForm)
  } else {
    router.push('/request-quote').then(() => {
      useTimeoutFn(() => {
        const estimateFormOnContactPage = document.querySelector('#estimate-form')
        if (estimateFormOnContactPage) {
          scrollToElement(estimateFormOnContactPage)
        }
      }, 500)
    })
  }
}

const scrollToElement = (element) => {
  const firstInput = element.querySelector('input')
  if (firstInput) {
    firstInput.focus()
  }
  const adjestOffset = router.currentRoute.value.path === '/contact-us' ? -100 : 80
  window.scrollTo({
    behavior: 'smooth',
    top: element.offsetTop - adjestOffset
  })
}
</script>

<style scoped>
a:hover {
  color: white;
}
</style>
